import * as React from 'react'
import { navigate } from '@reach/router'
import { isLoggedIn } from '../../utils/auth'

interface Props {
  component: any
  location: any
}

class PrivateRoute extends React.Component<Props> {
  render() {
    const { component: Component, location, ...rest } = this.props
    if (!isLoggedIn()) {
      navigate(`/login`)
      return null
    }
    return <Component {...rest} />
  }
}

export default PrivateRoute
