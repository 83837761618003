// tslint:disable
// this is an auto generated file. This will be overwritten

export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    firstName
    lastName
    telephone
    email
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstName
      lastName
      telephone
      email
    }
    nextToken
  }
}
`;
export const getAgency = `query GetAgency($id: ID!) {
  getAgency(id: $id) {
    id
    name
  }
}
`;
export const listAgencys = `query ListAgencys(
  $filter: ModelAgencyFilterInput
  $limit: Int
  $nextToken: String
) {
  listAgencys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
    }
    nextToken
  }
}
`;
export const getBooking = `query GetBooking($id: ID!) {
  getBooking(id: $id) {
    id
    user
    voyages {
      number
      cabinType
      cabin
      portFee
      price
    }
    status
    bookedDate
    depositDate
    agencyID
    agentID
    total
    currency
    historyCurrency
    deposit
    stripeToken
    notes
    guests {
      firstName
      lastName
      email
      phone
      address1
      address2
      country
      city
      state
      zip
    }
    discount
    paid
  }
}
`;
export const listBookings = `query ListBookings(
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user
      voyages {
        number
        cabinType
        cabin
        portFee
        price
      }
      status
      bookedDate
      depositDate
      agencyID
      agentID
      total
      currency
      historyCurrency
      deposit
      stripeToken
      notes
      guests {
        firstName
        lastName
        email
        phone
        address1
        address2
        country
        city
        state
        zip
      }
      discount
      paid
    }
    nextToken
  }
}
`;
export const getVoyage = `query GetVoyage($id: ID!) {
  getVoyage(id: $id) {
    id
    number
    name
    startDate
    endDate
    departure
    arrival
    stops {
      destination
      date
    }
    ship
    portFee
    cabinTypes {
      name
      description
      squareFeet
      meters
      blueprintImage {
        bucket
        region
        key
      }
      images {
        bucket
        region
        key
      }
      balcony
      price
    }
  }
}
`;
export const listVoyages = `query ListVoyages(
  $filter: ModelVoyageFilterInput
  $limit: Int
  $nextToken: String
) {
  listVoyages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      number
      name
      startDate
      endDate
      departure
      arrival
      stops {
        destination
        date
      }
      ship
      portFee
      cabinTypes {
        name
        description
        squareFeet
        meters
        balcony
        price
      }
    }
    nextToken
  }
}
`;
export const getShip = `query GetShip($id: ID!) {
  getShip(id: $id) {
    id
    name
    images {
      bucket
      region
      key
    }
    decks {
      id
      number
      images {
        bucket
        region
        key
      }
    }
    cabinTypes {
      name
      description
      squareFeet
      meters
      blueprintImage {
        bucket
        region
        key
      }
      images {
        bucket
        region
        key
      }
      balcony
      price
    }
    cabins {
      cabinType
      number
      handicapped
      deck {
        id
        number
      }
      hold
      booked
      joint
    }
  }
}
`;
export const listShips = `query ListShips(
  $filter: ModelShipFilterInput
  $limit: Int
  $nextToken: String
) {
  listShips(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      images {
        bucket
        region
        key
      }
      decks {
        id
        number
      }
      cabinTypes {
        name
        description
        squareFeet
        meters
        balcony
        price
      }
      cabins {
        cabinType
        number
        handicapped
        hold
        booked
        joint
      }
    }
    nextToken
  }
}
`;
export const getDestination = `query GetDestination($id: ID!) {
  getDestination(id: $id) {
    id
    name
    description
    images {
      bucket
      region
      key
    }
  }
}
`;
export const listDestinations = `query ListDestinations(
  $filter: ModelDestinationFilterInput
  $limit: Int
  $nextToken: String
) {
  listDestinations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      images {
        bucket
        region
        key
      }
    }
    nextToken
  }
}
`;
export const searchVoyages = `query SearchVoyages(
  $filter: SearchableVoyageFilterInput
  $sort: SearchableVoyageSortInput
  $limit: Int
  $nextToken: Int
) {
  searchVoyages(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      number
      name
      startDate
      endDate
      departure
      arrival
      stops {
        destination
        date
      }
      ship
      portFee
      cabinTypes {
        name
        description
        squareFeet
        meters
        balcony
        price
      }
    }
    nextToken
  }
}
`;
