import * as React from 'react'
import BookingsNewForm from './BookingsNewForm'
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'shards-react'
import PageTitle from './../PageTitle'
import { Link } from 'gatsby'

export default class BookingsNew extends React.PureComponent {
  render() {
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Bookings"
            subtitle="Add New Booking"
            className="text-sm-left mb-3"
          />
        </Row>
        <Row>
          <Col lg="12">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/app/bookings">Bookings</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>New Booking</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <BookingsNewForm />
        </Row>
      </Container>
    )
  }
}
