import * as React from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { createDestination } from './../../graphql/mutations'
import { Form, Field } from 'react-final-form'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormInput,
} from 'shards-react'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

// TODO: move to offerSubmit and save
const onSubmit = async values => {
  await sleep(300)
  // For testing
  window.alert(JSON.stringify(values, 0, 2))

  // Mutation
  const destinationDetails = {
    name: values.name,
    description: values.description,
  }
  const newDestination = await API.graphql(
    graphqlOperation(createDestination, { input: destinationDetails })
  )
  console.log(newDestination)
}

class DestinationsNewForm extends React.PureComponent {
  render() {
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={{}}
        render={({
          handleSubmit,
          reset,
          submitting,
          pristine,
          values,
          active,
          form,
        }) => (
          <Col lg="12">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Add New Destination</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <form onSubmit={handleSubmit}>
                  <div className="container">
                    <div className="row">
                      <div className="column">
                        <div className="columnBg">
                          <div>
                            <label>Name</label>
                            <Field
                              name="name"
                              component="input"
                              type="text"
                              placeholder="Name"
                            />
                          </div>
                          <div>
                            <label>Description</label>
                            <Field
                              name="number"
                              component="textarea"
                              type="text"
                              placeholder="Description"
                            />
                          </div>
                          <div className="buttons">
                            <button
                              type="button"
                              onClick={form.reset}
                              disabled={submitting || pristine}
                            >
                              Reset
                            </button>
                            <button
                              type="submit"
                              disabled={submitting || pristine}
                            >
                              Create
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </CardBody>
            </Card>
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </Col>
        )}
      />
    )
  }
}

export default DestinationsNewForm
