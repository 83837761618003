import * as React from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { createVoyage } from './../../graphql/mutations'
import { listDestinations, listShips } from './../../graphql/queries'
import { Form, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import * as moment from 'moment'
import { Row, Col, Card, CardHeader, CardBody, FormInput } from 'shards-react'
import DatePickerAdapter from './../DatePickerAdapter'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const onSubmit = async values => {
  await sleep(300)
  // For testing
  window.alert(JSON.stringify(values, 0, 2))

  // Mutation
  const details = {
    name: values.name,
    number: values.number,
    departure: values.departure,
    startDate: values.startDate,
    arrival: values.arrival,
    endDate: values.endDate,
    ship: values.ship,
    portFee: values.portFee,
    cabinTypes: values.cabinTypes,
    stops: values.stops,
  }
  const newItem = await API.graphql(
    graphqlOperation(createVoyage, { input: details })
  )
  console.log(newItem)
}

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

class VoyagesNewForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      destinations: [],
      ships: [],
    }
  }
  async componentDidMount() {
    const allDestinations = await API.graphql(
      graphqlOperation(listDestinations)
    )
    this.setState({
      destinations: allDestinations.data.listDestinations.items,
    })
    // console.log(this.state.destinations)

    const allShips = await API.graphql(graphqlOperation(listShips))
    this.setState({
      ships: allShips.data.listShips.items,
    })
    // console.log(this.state.ships)
  }

  render() {
    let { destinations, ships } = this.state

    return (
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          startDate: new Date(),
          endDate: new Date(),
        }}
        render={({
          handleSubmit,
          form: {
            // mutators are injected by final-form-arrays
            mutators: { push, pop },
          },
          reset,
          submitting,
          pristine,
          values,
          active,
          form,
        }) => (
          <Col lg="12">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Add New Voyage</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <form onSubmit={handleSubmit}>
                  <div className="container">
                    <div className="row">
                      <div className="column">
                        <div className="columnBg">
                          <div>
                            <label>Name</label>
                            <Field
                              name="name"
                              component="input"
                              type="text"
                              placeholder="Name"
                            />
                          </div>
                          <div>
                            <label>Number</label>
                            <Field
                              name="number"
                              component="input"
                              type="text"
                              placeholder="Voyage Number"
                            />
                          </div>
                          <div>
                            <label>Depart. Port</label>
                            <Field name="departure" component="select">
                              <option />
                              {destinations.map((item, key) => {
                                return (
                                  <option key={key} value={item.name}>
                                    {item.name}
                                  </option>
                                )
                              })}
                            </Field>
                          </div>
                          <div>
                            <label>Depart. Date</label>
                            <Field
                              name="startDate"
                              component={DatePickerAdapter}
                            />
                          </div>
                          <div>
                            <label>Arrival Port</label>
                            <Field name="arrival" component="select">
                              <option />
                              {destinations.map((item, key) => {
                                return (
                                  <option key={key} value={item.name}>
                                    {item.name}
                                  </option>
                                )
                              })}
                            </Field>
                          </div>
                          <div>
                            <label>Arrival Date</label>
                            <Field
                              name="endDate"
                              component={DatePickerAdapter}
                            />
                          </div>
                          <div>
                            <label>Ship</label>
                            <Field name="ship" component="select">
                              // TODO: loop ships
                              <option />
                              {ships.map((item, key) => {
                                return (
                                  <option key={key} value={item.name}>
                                    {item.name}
                                  </option>
                                )
                              })}
                            </Field>
                          </div>
                          <Row>
                            <Col>
                              <label>Port Fee</label>
                              <Field
                                name="portFee"
                                component="input"
                                placeholder="Port Fee"
                              />
                            </Col>
                          </Row>
                          <FieldArray name="cabinTypes">
                            {({ fields }) =>
                              fields.map((cabinTypes, index) => (
                                <div key={cabinTypes}>
                                  <div>
                                    <label>CabinType.</label>
                                    <Field
                                      name={`${cabinTypes}.name`}
                                      component="input"
                                      placeholder="Name"
                                    />
                                    <label>Price</label>
                                    <Field
                                      name={`${cabinTypes}.price`}
                                      component="input"
                                      placeholder="Price"
                                    />
                                    {/*
                                    <label>Description</label>
                                    <Field
                                      name={`${stateroomType}.description`}
                                      component="textarea"
                                      placeholder="Description"
                                    />
                                    <label>Room Size</label>
                                    <Field
                                      name={`${stateroomType}.squareFeet`}
                                      component="input"
                                      placeholder="Square Feet"
                                    />
                                    <Field
                                      name={`${stateroomType}.meters`}
                                      component="input"
                                      placeholder="Meters"
                                    />
                                    <label>Blueprint Image</label>
                                    <Field
                                      name={`${stateroomType}.blueprintImage`}
                                      component="input"
                                      placeholder="Blueprint Image"
                                    />
                                    <label>Stateroom Image</label>
                                    <Field
                                      name={`${stateroomType}.stateroomImage`}
                                      component="input"
                                      placeholder="Stateroom Image"
                                    />
                                    <label>Balcony</label>
                                    <Field
                                      name="balcony"
                                      component="input"
                                      type="checkbox"
                                    />
                                    */}
                                    <span
                                      onClick={() => fields.remove(index)}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ❌
                                    </span>
                                  </div>
                                </div>
                              ))
                            }
                          </FieldArray>
                          <div className="buttons">
                            <button
                              type="button"
                              onClick={() => push('cabinTypes', undefined)}
                            >
                              Add Cabin Type
                            </button>
                          </div>
                          <FieldArray name="stops">
                            {({ fields }) =>
                              fields.map((stop, index) => (
                                <div key={stop}>
                                  <label>Stop. #{index + 1}</label>
                                  <Field
                                    name={`${stop}.destination`}
                                    component="select"
                                  >
                                    <option />
                                    {destinations.map((item, key) => {
                                      return (
                                        <option key={key} value={item.name}>
                                          {item.name}
                                        </option>
                                      )
                                    })}
                                  </Field>
                                  <Field
                                    name={`${stop}.date`}
                                    component={DatePickerAdapter}
                                  />
                                  <span
                                    onClick={() => fields.remove(index)}
                                    style={{ cursor: 'pointer' }}
                                    className="removeDestination"
                                  >
                                    ❌
                                  </span>
                                </div>
                              ))
                            }
                          </FieldArray>
                          <div className="destinationButtons">
                            <button
                              type="button"
                              onClick={() => push('stops', undefined)}
                            >
                              Add Destination
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Col>
                        <div className="buttons">
                          <button
                            type="button"
                            onClick={form.reset}
                            disabled={submitting || pristine}
                          >
                            Reset
                          </button>
                          <button
                            type="submit"
                            disabled={submitting || pristine}
                          >
                            Create
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </form>
              </CardBody>
            </Card>
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </Col>
        )}
      />
    )
  }
}

export default VoyagesNewForm
