import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export const DatePickerAdapter = ({ input, selected, ...rest }) => (
  <DatePicker
    {...input}
    {...rest}
    // selected={event}
    onChange={(event, value) => {
      input.onChange(event)
      console.log(event)
    }}
    // Add time
    // showTimeSelect
    // timeFormat="HH:mm"
    // dateFormat="MMMM d, yyyy h:mm aa"
  />
)
