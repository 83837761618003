import * as React from 'react'
import { navigate } from '@reach/router'
import { API, graphqlOperation } from 'aws-amplify'
import { createUser } from './../../graphql/mutations'
import Error from './../Error'
import { Auth } from 'aws-amplify'
import { Col, Card, CardHeader, CardBody, Button } from 'shards-react'

const initialState = {
  firstName: ``,
  lastName: ``,
  username: ``,
  password: ``,
  email: '',
  phone_number: '',
  authCode: '',
  stage: 0,
  error: '',
}

class UsersNewForm extends React.Component {
  state = initialState

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  signUp = async () => {
    const {
      firstName,
      lastName,
      username,
      password,
      email,
      phone_number,
    } = this.state
    try {
      await Auth.signUp({
        username,
        password,
        attributes: { email, phone_number },
      })
      this.setState({ stage: 1 })
      // Mutation
      // TODO: telephone or phone_number (refactor!)
      const userDetails = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        telephone: phone_number,
      }
      const newUser = await API.graphql(
        graphqlOperation(createUser, { input: userDetails })
      )
      console.log(newUser)
    } catch (err) {
      this.setState({ error: err })
      console.log('error signing up...', err)
    }
  }

  confirmSignUp = async () => {
    const { username, authCode } = this.state
    try {
      await Auth.confirmSignUp(username, authCode)
      alert('Successfully signed up!')
      navigate('/app/booking')
    } catch (err) {
      this.setState({ error: err })
      console.log('error confirming signing up...', err)
    }
  }

  render() {
    return (
      <Col lg="12">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Add New User</h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <div>
              {this.state.stage === 0 && (
                <div style={styles.formContainer}>
                  {this.state.error && (
                    <Error errorMessage={this.state.error} />
                  )}
                  <input
                    onChange={this.handleUpdate}
                    placeholder="First Name"
                    name="firstName"
                    value={this.state.firstName}
                    style={styles.input}
                  />
                  <input
                    onChange={this.handleUpdate}
                    placeholder="Last Name"
                    name="lastName"
                    value={this.state.lastName}
                    style={styles.input}
                  />
                  <input
                    onChange={this.handleUpdate}
                    placeholder="Email"
                    name="email"
                    value={this.state.email}
                    style={styles.input}
                  />
                  <input
                    onChange={this.handleUpdate}
                    placeholder="Password"
                    name="password"
                    value={this.state.password}
                    type="password"
                    style={styles.input}
                  />
                  <input
                    onChange={this.handleUpdate}
                    placeholder="Username"
                    name="username"
                    value={this.state.username}
                    style={styles.input}
                  />
                  <input
                    onChange={this.handleUpdate}
                    placeholder="Phone Number"
                    name="phone_number"
                    value={this.state.phone_number}
                    style={styles.input}
                  />
                  <div style={styles.button} onClick={this.signUp}>
                    <span style={styles.buttonText}>Sign Up</span>
                  </div>
                </div>
              )}
              {this.state.stage === 1 && (
                <div style={styles.formContainer}>
                  {this.state.error && (
                    <Error errorMessage={this.state.error} />
                  )}
                  <input
                    onChange={this.handleUpdate}
                    placeholder="Authorization Code"
                    name="authCode"
                    value={this.state.authCode}
                    style={styles.input}
                  />
                  <Button>Test</Button>
                  <div style={styles.button} onClick={this.confirmSignUp}>
                    <span style={styles.buttonText}>Confirm Sign Up</span>
                  </div>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
        {/*
        // TODO: add final-form to UsersNewForm
        // <pre>{JSON.stringify(values, 0, 2)}</pre>
        */}
      </Col>
    )
  }
}

// TODO: move styles into its own file
const styles = {
  input: {
    height: 40,
    margin: '10px 0px',
    padding: 7,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    backgroundColor: '#06274d',
    padding: '15px 7px',
    cursor: 'pointer',
    textAlign: 'center',
    marginBottom: 10,
  },
  buttonText: {
    color: 'white',
  },
}

export default UsersNewForm
