import * as React from 'react'
import { Form, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { Col, Card, CardHeader, CardBody } from 'shards-react'
import { API, graphqlOperation } from 'aws-amplify'
import { createShip } from './../../graphql/mutations'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const onSubmit = async values => {
  await sleep(300)
  // For testing
  window.alert(JSON.stringify(values, 0, 2))

  // Mutation
  const shipDetails = {
    name: values.name,
    cabinTypes: values.cabinTypes,
    cabins: values.cabins,
  }
  const newShip = await API.graphql(
    graphqlOperation(createShip, { input: shipDetails })
  )
  console.log(newShip)
}

export default class ShipsNewForm extends React.PureComponent {
  render() {
    return (
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        render={({
          handleSubmit,
          form: {
            mutators: { push },
          }, // injected from final-form-arrays above
          pristine,
          reset,
          submitting,
          values,
        }) => {
          return (
            <Col lg="12">
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Add New Ship</h6>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <form onSubmit={handleSubmit}>
                    <Col md="6" className="form-group">
                      <div>
                        <label>Name</label>
                        <Field name="name" component="input" />
                      </div>
                      {/*
                      <FieldArray name="decks">
                        {({ fields }) =>
                          fields.map((number, index) => (
                            <div key={number}>
                              <label>Deck. #</label>
                              <Field
                                name={number}
                                component="input"
                                placeholder="Deck"
                              />
                              <Field
                              name={image}
                              component="input"
                              placeholder="Image"
                              />
                              <span
                                onClick={() => fields.remove(index)}
                                style={{ cursor: 'pointer' }}
                              >
                                ❌
                              </span>
                            </div>
                          ))
                        }
                      </FieldArray>
                      <div className="buttons">
                        <button
                          type="button"
                          onClick={() => push('decks', undefined)}
                        >
                          Add Deck
                        </button>
                      </div>
                      */}
                      {/*
                      <FieldArray name="locationGuide">
                        {({ fields }) =>
                          fields.map((locationGuide, index) => (
                            <div key={locationGuide}>
                              <label>Location Guide #</label>
                              <Field
                                name={`${locationGuide}.number`}
                                component="input"
                                placeholder="Number"
                              />
                              <Field
                                name={`${locationGuide}.name`}
                                component="input"
                                placeholder="Name"
                              />
                              <span
                                onClick={() => fields.remove(index)}
                                style={{ cursor: 'pointer' }}
                              >
                                ❌
                              </span>
                            </div>
                          ))
                        }
                      </FieldArray>
                      <div className="buttons">
                        <button
                          type="button"
                          onClick={() => push('locationGuide', undefined)}
                        >
                          Add Location Guide
                        </button>
                      </div>
                      */}
                    </Col>
                    <Col>
                      <FieldArray name="cabinTypes">
                        {({ fields }) =>
                          fields.map((cabinTypes, index) => (
                            <div key={cabinTypes}>
                              <div>
                                <label>CabinType.</label>
                                <Field
                                  name={`${cabinTypes}.name`}
                                  component="input"
                                  placeholder="Name"
                                />
                                <label>Price</label>
                                <Field
                                  name={`${cabinTypes}.price`}
                                  component="input"
                                  placeholder="Price"
                                />
                                {/*
                                <label>Description</label>
                                <Field
                                  name={`${stateroomType}.description`}
                                  component="textarea"
                                  placeholder="Description"
                                />
                                <label>Room Size</label>
                                <Field
                                  name={`${stateroomType}.squareFeet`}
                                  component="input"
                                  placeholder="Square Feet"
                                />
                                <Field
                                  name={`${stateroomType}.meters`}
                                  component="input"
                                  placeholder="Meters"
                                />
                                <label>Blueprint Image</label>
                                <Field
                                  name={`${stateroomType}.blueprintImage`}
                                  component="input"
                                  placeholder="Blueprint Image"
                                />
                                <label>Stateroom Image</label>
                                <Field
                                  name={`${stateroomType}.stateroomImage`}
                                  component="input"
                                  placeholder="Stateroom Image"
                                />
                                <label>Balcony</label>
                                <Field
                                  name="balcony"
                                  component="input"
                                  type="checkbox"
                                />
                                */}
                                <span
                                  onClick={() => fields.remove(index)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  ❌
                                </span>
                              </div>
                            </div>
                          ))
                        }
                      </FieldArray>
                      <div className="buttons">
                        <button
                          type="button"
                          onClick={() => push('cabinTypes', undefined)}
                        >
                          Add Cabin Type
                        </button>
                      </div>
                      <FieldArray name="cabins">
                        {({ fields }) =>
                          fields.map((cabins, index) => (
                            <div key={cabins}>
                              <div>
                                <label>Cabin. #</label>
                                <Field
                                  name={`${cabins}.number`}
                                  component="input"
                                  placeholder="Number"
                                />
                                {/*
                                <label>Handicapped</label>
                                <Field
                                  name="handicapped"
                                  component="input"
                                  type="checkbox"
                                />
                                <label>Joint</label>
                                <Field
                                  name="joint"
                                  component="input"
                                  type="checkbox"
                                />
                                */}
                                <label>Cabin Type</label>
                                <Field
                                  name={`${cabins}.cabinType`}
                                  component="select"
                                >
                                  // TODO: loop ports here
                                  <option />
                                  <option>Studio</option>
                                  <option>1 Bedroom</option>
                                </Field>
                                <span
                                  onClick={() => fields.remove(index)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  ❌
                                </span>
                              </div>
                            </div>
                          ))
                        }
                      </FieldArray>
                      <div className="buttons">
                        <button
                          type="button"
                          onClick={() => push('cabins', undefined)}
                        >
                          Add Cabin
                        </button>
                      </div>
                      <div className="buttons">
                        <button type="submit" disabled={submitting || pristine}>
                          Create
                        </button>
                      </div>
                    </Col>
                  </form>
                </CardBody>
              </Card>
              <pre>{JSON.stringify(values, 0, 2)}</pre>
            </Col>
          )
        }}
      />
    )
  }
}
