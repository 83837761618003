// tslint:disable
// this is an auto generated file. This will be overwritten

export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    firstName
    lastName
    telephone
    email
  }
}
`;
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    firstName
    lastName
    telephone
    email
  }
}
`;
export const deleteUser = `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    firstName
    lastName
    telephone
    email
  }
}
`;
export const createAgency = `mutation CreateAgency($input: CreateAgencyInput!) {
  createAgency(input: $input) {
    id
    name
  }
}
`;
export const updateAgency = `mutation UpdateAgency($input: UpdateAgencyInput!) {
  updateAgency(input: $input) {
    id
    name
  }
}
`;
export const deleteAgency = `mutation DeleteAgency($input: DeleteAgencyInput!) {
  deleteAgency(input: $input) {
    id
    name
  }
}
`;
export const createBooking = `mutation CreateBooking($input: CreateBookingInput!) {
  createBooking(input: $input) {
    id
    user
    voyages {
      number
      cabinType
      cabin
      portFee
      price
    }
    status
    bookedDate
    depositDate
    agencyID
    agentID
    total
    currency
    historyCurrency
    deposit
    stripeToken
    notes
    guests {
      firstName
      lastName
      email
      phone
      address1
      address2
      country
      city
      state
      zip
    }
    discount
    paid
  }
}
`;
export const updateBooking = `mutation UpdateBooking($input: UpdateBookingInput!) {
  updateBooking(input: $input) {
    id
    user
    voyages {
      number
      cabinType
      cabin
      portFee
      price
    }
    status
    bookedDate
    depositDate
    agencyID
    agentID
    total
    currency
    historyCurrency
    deposit
    stripeToken
    notes
    guests {
      firstName
      lastName
      email
      phone
      address1
      address2
      country
      city
      state
      zip
    }
    discount
    paid
  }
}
`;
export const deleteBooking = `mutation DeleteBooking($input: DeleteBookingInput!) {
  deleteBooking(input: $input) {
    id
    user
    voyages {
      number
      cabinType
      cabin
      portFee
      price
    }
    status
    bookedDate
    depositDate
    agencyID
    agentID
    total
    currency
    historyCurrency
    deposit
    stripeToken
    notes
    guests {
      firstName
      lastName
      email
      phone
      address1
      address2
      country
      city
      state
      zip
    }
    discount
    paid
  }
}
`;
export const createVoyage = `mutation CreateVoyage($input: CreateVoyageInput!) {
  createVoyage(input: $input) {
    id
    number
    name
    startDate
    endDate
    departure
    arrival
    stops {
      destination
      date
    }
    ship
    portFee
    cabinTypes {
      name
      description
      squareFeet
      meters
      blueprintImage {
        bucket
        region
        key
      }
      images {
        bucket
        region
        key
      }
      balcony
      price
    }
  }
}
`;
export const updateVoyage = `mutation UpdateVoyage($input: UpdateVoyageInput!) {
  updateVoyage(input: $input) {
    id
    number
    name
    startDate
    endDate
    departure
    arrival
    stops {
      destination
      date
    }
    ship
    portFee
    cabinTypes {
      name
      description
      squareFeet
      meters
      blueprintImage {
        bucket
        region
        key
      }
      images {
        bucket
        region
        key
      }
      balcony
      price
    }
  }
}
`;
export const deleteVoyage = `mutation DeleteVoyage($input: DeleteVoyageInput!) {
  deleteVoyage(input: $input) {
    id
    number
    name
    startDate
    endDate
    departure
    arrival
    stops {
      destination
      date
    }
    ship
    portFee
    cabinTypes {
      name
      description
      squareFeet
      meters
      blueprintImage {
        bucket
        region
        key
      }
      images {
        bucket
        region
        key
      }
      balcony
      price
    }
  }
}
`;
export const createShip = `mutation CreateShip($input: CreateShipInput!) {
  createShip(input: $input) {
    id
    name
    images {
      bucket
      region
      key
    }
    decks {
      id
      number
      images {
        bucket
        region
        key
      }
    }
    cabinTypes {
      name
      description
      squareFeet
      meters
      blueprintImage {
        bucket
        region
        key
      }
      images {
        bucket
        region
        key
      }
      balcony
      price
    }
    cabins {
      cabinType
      number
      handicapped
      deck {
        id
        number
      }
      hold
      booked
      joint
    }
  }
}
`;
export const updateShip = `mutation UpdateShip($input: UpdateShipInput!) {
  updateShip(input: $input) {
    id
    name
    images {
      bucket
      region
      key
    }
    decks {
      id
      number
      images {
        bucket
        region
        key
      }
    }
    cabinTypes {
      name
      description
      squareFeet
      meters
      blueprintImage {
        bucket
        region
        key
      }
      images {
        bucket
        region
        key
      }
      balcony
      price
    }
    cabins {
      cabinType
      number
      handicapped
      deck {
        id
        number
      }
      hold
      booked
      joint
    }
  }
}
`;
export const deleteShip = `mutation DeleteShip($input: DeleteShipInput!) {
  deleteShip(input: $input) {
    id
    name
    images {
      bucket
      region
      key
    }
    decks {
      id
      number
      images {
        bucket
        region
        key
      }
    }
    cabinTypes {
      name
      description
      squareFeet
      meters
      blueprintImage {
        bucket
        region
        key
      }
      images {
        bucket
        region
        key
      }
      balcony
      price
    }
    cabins {
      cabinType
      number
      handicapped
      deck {
        id
        number
      }
      hold
      booked
      joint
    }
  }
}
`;
export const createDestination = `mutation CreateDestination($input: CreateDestinationInput!) {
  createDestination(input: $input) {
    id
    name
    description
    images {
      bucket
      region
      key
    }
  }
}
`;
export const updateDestination = `mutation UpdateDestination($input: UpdateDestinationInput!) {
  updateDestination(input: $input) {
    id
    name
    description
    images {
      bucket
      region
      key
    }
  }
}
`;
export const deleteDestination = `mutation DeleteDestination($input: DeleteDestinationInput!) {
  deleteDestination(input: $input) {
    id
    name
    description
    images {
      bucket
      region
      key
    }
  }
}
`;
