import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'shards-react'
import { Link } from 'gatsby'

import PageTitle from './../PageTitle'
import SmallStats from './../SmallStats'
import VoyageSalesOpportunities from './../VoyageSalesOpportunities'
import SEO from './../SEO'

const Dashboard = ({ smallStats }) => (
  <Container fluid className="main-content-container px-4">
    <SEO title="Dashboard" />
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle
        title="Overview"
        subtitle="Dashboard"
        className="text-sm-left mb-3"
      />
    </Row>
    {/* Small Stats Blocks */}
    <Row>
      {smallStats.map((stats, idx) => (
        <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
          <SmallStats
            id={`small-stats-${idx}`}
            variation="1"
            chartData={stats.datasets}
            chartLabels={stats.chartLabels}
            label={stats.label}
            value={stats.value}
            percentage={stats.percentage}
            increase={stats.increase}
            decrease={stats.decrease}
          />
        </Col>
      ))}
    </Row>
    <Row>
      <Col lg="12" md="12" sm="12" className="mb-4">
        <VoyageSalesOpportunities />
      </Col>
    </Row>
  </Container>
)

Dashboard.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array,
}

Dashboard.defaultProps = {
  smallStats: [
    {
      label: 'Gross volume',
      value: '$1.53M',
      percentage: '4.7%',
      increase: true,
      tLabels: [null, null, null, null, null, null, null],
      attrs: { md: '6', sm: '6' },
      datasets: [
        {
          label: 'Today',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: 'rgba(0, 184, 216, 0.1)',
          borderColor: 'rgb(0, 184, 216)',
          data: [1, 2, 1, 3, 5, 4, 7],
        },
      ],
    },
    {
      label: 'Successful payments',
      value: '621',
      percentage: '12.4%',
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: '6', sm: '6' },
      datasets: [
        {
          label: 'Today',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: 'rgba(23,198,113,0.1)',
          borderColor: 'rgb(23,198,113)',
          data: [1, 2, 3, 3, 3, 4, 4],
        },
      ],
    },
    {
      label: 'Net volume from sales',
      value: '$1.42M',
      percentage: '3.8%',
      increase: true,
      decrease: false,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: '4', sm: '6' },
      datasets: [
        {
          label: 'Today',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: 'rgba(255,180,0,0.1)',
          borderColor: 'rgb(255,180,0)',
          data: [2, 3, 3, 3, 4, 3, 3],
        },
      ],
    },
    {
      label: 'Deposited Yesterday',
      value: '$36,420.32',
      percentage: '29%',
      increase: true,
      decrease: false,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: '4', sm: '6' },
      datasets: [
        {
          label: 'Today',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: 'rgba(255,65,105,0.1)',
          borderColor: 'rgb(255,65,105)',
          data: [1, 7, 1, 3, 1, 4, 8],
        },
      ],
    },
    {
      label: 'Expected Today',
      value: '$59,557.69',
      percentage: '2.4%',
      increase: true,
      decrease: false,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: '4', sm: '6' },
      datasets: [
        {
          label: 'Today',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: 'rgb(0,123,255,0.1)',
          borderColor: 'rgb(0,123,255)',
          data: [3, 2, 3, 2, 4, 5, 4],
        },
      ],
    },
  ],
}

export default Dashboard
