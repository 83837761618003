import * as React from 'react'
// import PropTypes from 'prop-types'
import { API, graphqlOperation } from 'aws-amplify'
// import { createBooking } from './../../graphql/mutations'
// import { Connect } from 'aws-amplify-react'
import { listVoyages } from './../../graphql/queries'
// import { onCreateVoyage } from './../../graphql/subscriptions'
import { Form, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import * as moment from 'moment'
import PayButton from './../PayButton'

import { Container, Row, Col, Card, CardHeader, CardBody } from 'shards-react'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

// TODO: move to offerSubmit and save
const onSubmit = async values => {
  await sleep(300)
  // window.alert(JSON.stringify(values, 0, 2))
}

// TODO: add offer Mutation
// const details = {
//   user: '1',
//   voyages: values.voyages,
//   status: 'Offered',
//   bookedDate: values.bookedDate,
//   depositDate: values.depositDate,
//   // agencyID: values.agencyID,
//   // agentID: values.agentID,
//   total: values.total,
//   currency: values.currency,
//   deposit: values.deposit,
//   guests: values.guests,
//   discount: values.discount,
// }
// const newItem = await API.graphql(
//   graphqlOperation(createBooking, { input: details })
// )
// console.log(newItem)

const bookedDate = new Date()

// TODO: mock code until voyages
const depositDate = new Date().setDate(bookedDate.getDate() - 120)

// TODO: mock price until voyages
// Pay in Full 10% Savings
// const total = ({ booking }) => {
//   if (booking.deposit === true) {
//     return 10000
//   } else {
//     return '500'
//   }
// }

// const total = '1000'
// const total = async values => {
//   return 100
//   // return (
//   //   values.voyages.map(voyage) => {
//   //     voyage.price
//   //   }
//   // )
// }

// TODO: add the symbol based on curreny
// const currencySymbol = '$'
const currency = 'USD'

// TODO: add logic for this
const status = 'Booked'

// TODO: add logic for history of currency
const historyCurrency = (bookedDate, currency) => {
  return {
    historyCurrency: {
      bookedDate: {
        date: bookedDate,
        currency: currency,
        value: '1',
      },
    },
  }
}

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

class BookingsNewForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      allVoyages: {},
      voyagesSelected: [],
      totalPortsFee: null,
      totalVoyagesFee: null,
      discount: null,
      currencySymbol: '$',
      total: null,
    }
  }

  async componentDidMount() {
    const allVoyages = await API.graphql(graphqlOperation(listVoyages))
    this.setState({
      allVoyages: allVoyages.data.listVoyages.items,
    })
    console.log(this.state.allVoyages)
  }

  onVoyageSelection(event) {
    // this.setState({
    //   // voyagesSelected: event.target.value,
    //   // total: this.state.total + event.target.price,
    // })
    console.log(JSON.stringify(event.target.value))
    let voyage = event.target.value
    console.log(voyage.portFee)
  }

  render() {
    const {
      allVoyages,
      totalPortsFee,
      discount,
      currencySymbol,
      total,
    } = this.state
    const required = value => (value ? undefined : 'Required')

    return (
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          user: 1,
          status: status,
          // agentID: 1,
          // agencyID: 1,
          deposit: false,
          bookedDate: moment(bookedDate).format(),
          depositDate: moment(depositDate).format(),
          historyCurrency: historyCurrency,
          currency: currency,
          total: total,
        }}
        render={({
          handleSubmit,
          form: {
            // mutators are injected by final-form-arrays
            mutators: { push, pop },
          },
          reset,
          submitting,
          pristine,
          values,
          active,
          form,
        }) => (
          <Col lg="12">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Add New Booking</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <form onSubmit={handleSubmit}>
                  <Container>
                    <Row>
                      <Col>
                        <FieldArray name="voyages" validate={required}>
                          {({ fields }) =>
                            fields.map((voyage, index) => (
                              <div key={voyage}>
                                <label>Voyages</label>
                                <Field
                                  name={`${voyage}.number`}
                                  onChange={this.onVoyageSelection}
                                  component="select"
                                >
                                  <option />
                                  {allVoyages.map((item, key) => {
                                    return (
                                      <option key={key} value={item.number}>
                                        {item.number}
                                      </option>
                                    )
                                  })}
                                </Field>
                                <label>Cabin Type</label>
                                <Field
                                  name={`${voyage}.cabinType`}
                                  component="select"
                                >
                                  <option />
                                  {allVoyages.map((item, key) => {
                                    return (
                                      <option key={key} value={item.cabinTypes}>
                                        {item.cabinTypes}
                                      </option>
                                    )
                                  })}
                                </Field>
                                <label>Cabin</label>
                                <Field
                                  name={`${voyage}.cabin`}
                                  component="select"
                                >
                                  <option />
                                  <option value="400">400</option>
                                </Field>
                                <label>Port Fee:</label>
                                <span>
                                  {currencySymbol}
                                  {allVoyages[0].portFee}
                                </span>
                                <label>Price:</label>
                                <span>
                                  {currencySymbol}
                                  {allVoyages[0].portFee}
                                </span>
                                <span
                                  onClick={() => fields.remove(index)}
                                  style={{ cursor: 'pointer' }}
                                  className="removeVoyage"
                                >
                                  ❌
                                </span>
                                <hr />
                              </div>
                            ))
                          }
                        </FieldArray>
                        <div className="voyagesButtons">
                          <button
                            type="button"
                            onClick={() => push('voyages', undefined)}
                          >
                            Add Voyage
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FieldArray name="guests">
                          {({ fields }) =>
                            fields.map((guest, index) => (
                              <div key={guest}>
                                <label>Guest. #{index + 1}</label>
                                <Field
                                  name={`${guest}.firstName`}
                                  component="input"
                                  placeholder="First Name"
                                />
                                <Field
                                  name={`${guest}.lastName`}
                                  component="input"
                                  placeholder="Last Name"
                                />
                                <Field
                                  name={`${guest}.phone`}
                                  component="input"
                                  placeholder="Phone"
                                />
                                <Field
                                  name={`${guest}.email`}
                                  component="input"
                                  placeholder="Email"
                                />
                                <Field
                                  name={`${guest}.address1`}
                                  component="input"
                                  placeholder="Address"
                                />
                                <Field
                                  name={`${guest}.address2`}
                                  component="input"
                                  placeholder="Address"
                                />

                                <Field
                                  name={`${guest}.city`}
                                  component="input"
                                  placeholder="City"
                                />
                                <Field
                                  name={`${guest}.state`}
                                  component="input"
                                  placeholder="State"
                                />
                                <Field
                                  name={`${guest}.zip`}
                                  component="input"
                                  placeholder="Zip Code"
                                />
                                <Field
                                  name={`${guest}.country`}
                                  component="input"
                                  placeholder="Country"
                                />
                                <span
                                  onClick={() => fields.remove(index)}
                                  style={{ cursor: 'pointer' }}
                                  className="removeGuest"
                                >
                                  ❌
                                </span>
                                <hr />
                              </div>
                            ))
                          }
                        </FieldArray>
                        <div className="guestButtons">
                          <button
                            type="button"
                            onClick={() => push('guests', undefined)}
                          >
                            Add Guest
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <hr />
                        <label>Currency</label>
                        <Field name="currency" component="select">
                          <option>USD</option>
                          <option>EUR</option>
                          {/*<option>GBP</option>*/}
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div>
                          <label>Deposit Only</label>
                          <Field
                            name="deposit"
                            component="input"
                            type="checkbox"
                          />
                        </div>
                        <hr />
                        <Condition when="deposit" is={true}>
                          <div>
                            <label>25% Deposit Only</label>
                            <Row>
                              <Col>
                                Port Fee: {currencySymbol}
                                {totalPortsFee}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label>Discount</label>
                                {currencySymbol}
                                <Field name="discount" component="input" />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                Total Due Today:{' '}
                                <span className="depositTotal">
                                  {currencySymbol}
                                  {total}
                                </span>
                              </Col>
                            </Row>
                          </div>
                          <div>
                            Due on {moment(depositDate).format('MMM DD, YYYY')}
                            {': '}
                            <span className="depositTotal">
                              {currencySymbol}
                              {total}
                            </span>
                          </div>
                        </Condition>
                        <Condition when="deposit" is={false}>
                          <div>
                            <label>Full Payment</label>
                            <Row>
                              <Col>
                                <span className="fullPaymentTotal">
                                  Port Fee: {currencySymbol}
                                  {totalPortsFee}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label>Discount</label>
                                {currencySymbol}
                                <Field
                                  name="discount"
                                  component="input"
                                  placeholder="Discount"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                Total: {currencySymbol}
                                {total}
                              </Col>
                            </Row>
                          </div>
                        </Condition>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <hr />
                        <Field
                          name="notes"
                          component="textarea"
                          placeholder="Notes"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <button
                          type="button"
                          onClick={form.reset}
                          disabled={submitting || pristine}
                        >
                          Reset
                        </button>
                        {/*
                        <button type="submit" disabled={submitting || pristine}>
                          Offer
                        </button>
                        */}
                        <PayButton booking={values} />
                      </Col>
                    </Row>
                  </Container>
                </form>
              </CardBody>
            </Card>
            <pre>{JSON.stringify(values, 0, 2)}</pre>
            <pre>{JSON.stringify(values.voyages, 0, 2)}</pre>
          </Col>
        )}
      />
    )
  }
}

// BookingsNewForm.propTypes = {
//   total: PropTypes.string,
// }

export default BookingsNewForm
