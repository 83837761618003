import React from 'react'
import { Link } from 'gatsby'
import ShipsNewForm from './ShipsNewForm'
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'shards-react'
import PageTitle from './../PageTitle'

class ShipsNew extends React.PureComponent {
  render() {
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Ships"
            subtitle="Add New Ship"
            className="text-sm-left mb-3"
          />
        </Row>
        <Row>
          <Col lg="12">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/app/ships">Ships</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>New Ship</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <ShipsNewForm />
        </Row>
      </Container>
    )
  }
}

export default ShipsNew
