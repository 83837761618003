import * as React from 'react'
import { Link } from 'gatsby'
import { graphqlOperation } from 'aws-amplify'
import { Connect } from 'aws-amplify-react'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'shards-react'
import PageTitle from './../PageTitle'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { listBookings } from './../../graphql/queries'
import { onCreateBooking } from './../../graphql/subscriptions'
import SEO from './../SEO'

// Import React Table
import ReactTable from 'react-table'
import 'react-table/react-table.css'

class Bookings extends React.Component {
  onNewBooking = (prevQuery, newData) => {
    // When we get data about a new album, we need to put in into an object
    // with the same shape as the original query results, but with the new data added as well
    let updatedQuery = Object.assign({}, prevQuery)
    updatedQuery.listBookings.items = prevQuery.listBookings.items.concat([
      newData.onCreateBooking,
    ])
    return updatedQuery
  }
  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <SEO title="Bookings" />
        {/*
        <Query query={GET_BOOKINGS} pollInterval={25}>
          {({ loading, error, data }) => {
            if (loading) {
              return <Text>Get Bookings...</Text>
            }
            if (error) {
              return <Text>Get Bookings ERROR! {error}</Text>
            }
            return console.log(data)
          }}
        </Query>
        */}
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Bookings"
            subtitle="List of all Bookings"
            className="text-sm-left mb-3"
          />
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col>
                    <h6 className="m-0">Bookings</h6>
                  </Col>
                  <Col>
                    <Link className="buttonOveride" to="/app/bookings/new">
                      <Button>New Booking</Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <Connect
                  query={graphqlOperation(listBookings)}
                  subscription={graphqlOperation(onCreateBooking)}
                  onSubscriptionMsg={this.onNewBooking}
                >
                  {({ data: { listBookings }, loading, error }) => {
                    if (error) return <h3>Error</h3>
                    if (loading || !listBookings) return <h3>Loading...</h3>
                    return (
                      <ReactTable
                        data={listBookings.items}
                        columns={[
                          {
                            Header: '',
                            columns: [
                              {
                                Header: 'User',
                                accessor: 'user',
                              },
                              {
                                Header: 'Booked Date',
                                accessor: 'bookedDate',
                              },
                              {
                                Header: 'Currency',
                                accessor: 'currency',
                              },
                              {
                                Header: 'Total',
                                accessor: 'total',
                              },
                              {
                                Header: 'Paid',
                                accessor: 'paid',
                              },
                            ],
                          },
                        ]}
                        defaultPageSize={25}
                        className="-striped -highlight"
                      />
                    )
                    // <BookingsList bookings={listBookings.items} />
                  }}
                </Connect>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

// const GET_BOOKINGS = gql`
//   {
//     listBookings(limit: 10) {
//       items {
//         id
//         user
//         voyages
//         status
//         bookedDate
//         depositDate
//         agencyID
//         agentID
//         stateroom
//         total
//         currency
//         historyCurrency
//         deposit
//         stripeToken
//         notes
//       }
//     }
//   }
// `

export default Bookings

// <ul>
//   {bookings.map(item => (
//     <li key={item.id}>
//       {item.id} ({item.user})
//     </li>
//   ))}
// </ul>
// <Connect query={graphqlOperation(listBookings)}>
//   {({ data: { listBookings }, loading, error }) => {
//     if (error) return <h3>Error</h3>
//     if (loading || !listBookings) return <h3>Loading...</h3>
//     return <BookingsList bookings={listBookings.items} />
//   }}
// </Connect>
