import * as React from 'react'
import { Router } from '@reach/router'
import SEO from '../components/SEO'

// Add Private Routes Here
import Layout from '../components/Layout'
import Bookings from '../components/Bookings'
import BookingsNew from '../components/BookingsNew'
import Ships from '../components/Ships'
import ShipsNew from '../components/ShipsNew'
import Voyages from '../components/Voyages'
import VoyagesNew from '../components/VoyagesNew'
import Destinations from '../components/Destinations'
import DestinationsNew from '../components/DestinationsNew'
import Users from '../components/Users'
import UsersNew from '../components/UsersNew'
import Dashboard from '../components/Dashboard'
import Search from '../components/Search'
import Profile from '../components/Profile'
import PrivateRoute from '../components/PrivateRoute'

// Setup Amplify
import Amplify from 'aws-amplify'
import config from '../aws-exports'
Amplify.configure(config)

// Using bootstrap & react-shards to quickly style the app
import 'bootstrap/dist/css/bootstrap.min.css'
import './../shards-dashboard/styles/shards-dashboards.1.1.0.min.css'
import './../assets/layout.css'

// Define all private routes here.
// If you just need a simple page use `./src/pages`
// We're currently using gatsby graphql, plus appsync
// We're using Apollo Client to wrap the "App" with our grapql
const App = () => (
  <Layout>
    <SEO
      title="Global Yachting"
      keywords={[
        `global yachting`,
        `seadream`,
        `seadream I`,
        `seadream II`,
        `voyages`,
        `destinations`,
        `ports`,
      ]}
    />
    <Router>
      <PrivateRoute path="/app/bookings" component={Bookings} />
      <PrivateRoute path="/app/bookings/new" component={BookingsNew} />
      <PrivateRoute path="/app/ships" component={Ships} />
      <PrivateRoute path="/app/ships/new" component={ShipsNew} />
      <PrivateRoute path="/app/voyages" component={Voyages} />
      <PrivateRoute path="/app/voyages/new" component={VoyagesNew} />
      <PrivateRoute path="/app/destinations" component={Destinations} />
      <PrivateRoute path="/app/destinations/new" component={DestinationsNew} />
      <PrivateRoute path="/app/users" component={Users} />
      <PrivateRoute path="/app/users/new" component={UsersNew} />
      <PrivateRoute path="/app/dashboard" component={Dashboard} />
      <PrivateRoute path="app/search" component={Search} />
      <PrivateRoute path="/app/profile" component={Profile} />
    </Router>
  </Layout>
)

export default App
