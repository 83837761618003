import React from 'react'
import { Link } from 'gatsby'
import { getCurrentUser } from '../../utils/auth'

import { Container, Row, Col } from 'shards-react'

import PageTitle from '../PageTitle'
import UserDetails from './UserDetails'
import UserAccountDetails from './UserAccountDetails'

const Profile = () => {
  const user = getCurrentUser()
  console.log('user:', user)
  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="User Profile"
          subtitle="Overview"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      <Row>
        <Col lg="4">
          <UserDetails />
        </Col>
        <Col lg="8">
          <UserAccountDetails />
        </Col>
      </Row>
    </Container>
  )
}

export default Profile
//
//
// const Profile = () => {
//   const user = getCurrentUser()
//   console.log('user:', user)
//   return (
//     <div>
//       <h1>Profile</h1>
//       <p>Email: {user.email}</p>
//       <p>Phone: {user.phone_number}</p>
//       <p>Username: {user.username}</p>
//     </div>
//   )
// }
//
// export default Profile
