import * as React from 'react'
import { Container, Row, Col, Card, CardHeader, CardBody } from 'shards-react'
import namor from 'namor'

// import { render } from 'react-dom'

// Import React Table
import ReactTable from 'react-table'
import 'react-table/react-table.css'

const range = len => {
  const arr = []
  for (let i = 0; i < len; i++) {
    arr.push(i)
  }
  return arr
}

export function makeData(len = 5553) {
  return range(len).map(d => {
    return {
      ...newPerson(),
      children: range(10).map(newPerson),
    }
  })
}

const newPerson = () => {
  const statusChance = Math.random()
  return {
    firstName: namor.generate({ words: 1, numbers: 0 }),
    lastName: namor.generate({ words: 1, numbers: 0 }),
    age: Math.floor(Math.random() * 30),
    visits: Math.floor(Math.random() * 100),
    progress: Math.floor(Math.random() * 100),
    status:
      statusChance > 0.66
        ? 'relationship'
        : statusChance > 0.33
        ? 'complicated'
        : 'single',
  }
}

class VoyageSalesOpportunities extends React.Component {
  constructor() {
    super()
    this.state = {
      data: makeData(),
    }
  }
  render() {
    const { data } = this.state
    return (
      <div>
        {/* Default Light Table */}
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Voyage Sales Opportunity</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: '',
                      columns: [
                        {
                          Header: 'SAIL#',
                          accessor: 'firstName',
                        },
                        {
                          Header: 'DT',
                          id: 'lastName',
                          accessor: d => d.lastName,
                        },
                        {
                          Header: 'FROM',
                          id: 'lastName',
                          accessor: d => d.lastName,
                        },
                        {
                          Header: 'DAY',
                          id: 'lastName',
                          accessor: d => d.lastName,
                        },
                        {
                          Header: 'DYS',
                          id: 'lastName',
                          accessor: d => d.lastName,
                        },
                        {
                          Header: 'WHERE',
                          id: 'lastName',
                          accessor: d => d.lastName,
                        },
                        {
                          Header: 'WHERE',
                          id: 'lastName',
                          accessor: d => d.lastName,
                        },
                      ],
                    },
                    {
                      Header: 'Group',
                      columns: [
                        {
                          Header: 'TC',
                        },
                        {
                          Header: '%',
                        },
                      ],
                    },
                    {
                      Header: 'Status',
                      accessor: 'status',
                      Cell: row => (
                        <span>
                          <span
                            style={{
                              color:
                                row.value === 'relationship'
                                  ? '#ff2e00'
                                  : row.value === 'complicated'
                                  ? '#ffbf00'
                                  : '#57d500',
                              transition: 'all .3s ease',
                            }}
                          >
                            &#x25cf;
                          </span>{' '}
                          {row.value === 'relationship'
                            ? 'SOLD OUT'
                            : row.value === 'complicated'
                            ? `WARNING`
                            : 'CHARTER'}
                        </span>
                      ),
                    },
                    {
                      Header: '',
                      columns: [
                        {
                          Header: 'OCC/AVAIL',
                          accessor: 'progress',
                          Cell: row => (
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#dadada',
                                borderRadius: '2px',
                              }}
                            >
                              <div
                                style={{
                                  width: `${row.value}%`,
                                  height: '100%',
                                  backgroundColor:
                                    row.value > 66
                                      ? '#85cc00'
                                      : row.value > 33
                                      ? '#ffbf00'
                                      : '#ff2e00',
                                  borderRadius: '2px',
                                  transition: 'all .2s ease-out',
                                }}
                              />
                            </div>
                          ),
                        },
                      ],
                    },
                    {
                      Header: 'CABINS',
                      columns: [
                        {
                          Header: 'BKD',
                        },
                        {
                          Header: 'OFR',
                        },
                        {
                          Header: 'WL',
                        },
                        {
                          Header: 'BK+OF',
                        },
                        {
                          Header: 'AVL',
                        },
                        {
                          Header: 'OCC%',
                        },
                        {
                          Header: 'BKD',
                        },
                      ],
                    },
                    {
                      Header: 'PASSENGERS',
                      columns: [
                        {
                          Header: 'BKD',
                        },
                        {
                          Header: 'OFR',
                        },
                        {
                          Header: 'WL',
                        },
                      ],
                    },
                    {
                      Header: 'BK OF',
                      columns: [
                        {
                          Header: 'LWK',
                        },
                        {
                          Header: '24H',
                        },
                      ],
                    },
                  ]}
                  defaultPageSize={25}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default VoyageSalesOpportunities
