import * as React from 'react'
import { Link } from 'gatsby'
import { graphqlOperation } from 'aws-amplify'
import { Connect } from 'aws-amplify-react'
import { listVoyages } from './../../graphql/queries'
import { onCreateVoyage } from './../../graphql/subscriptions'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'shards-react'
import PageTitle from './../PageTitle'
import SEO from './../SEO'

// Import React Table
import ReactTable from 'react-table'
import 'react-table/react-table.css'

class Voyages extends React.Component {
  onNewVoyage = (prevQuery, newData) => {
    // When we get data about a new album, we need to put in into an object
    // with the same shape as the original query results, but with the new data added as well
    let updatedQuery = Object.assign({}, prevQuery)
    updatedQuery.listVoyages.items = prevQuery.listVoyages.items.concat([
      newData.onCreateVoyage,
    ])
    return updatedQuery
  }
  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <SEO title="Voyages" />
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Voyages"
            subtitle="List of all Voyages"
            className="text-sm-left mb-3"
          />
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col>
                    <h6 className="m-0">Voyages</h6>
                  </Col>
                  <Col>
                    <Link className="buttonOveride" to="/app/voyages/new">
                      <Button>New Voyages</Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <Connect
                  query={graphqlOperation(listVoyages)}
                  subscription={graphqlOperation(onCreateVoyage)}
                  onSubscriptionMsg={this.onNewVoyage}
                >
                  {({ data: { listVoyages }, loading, error }) => {
                    if (error) return <h3>Error</h3>
                    if (loading || !listVoyages) return <h3>Loading...</h3>
                    return (
                      <ReactTable
                        data={listVoyages.items}
                        columns={[
                          {
                            Header: '',
                            columns: [
                              {
                                Header: 'Name',
                                accessor: 'name',
                              },
                              {
                                Header: 'Number',
                                accessor: 'number',
                              },
                              {
                                Header: 'Start Date',
                                accessor: 'startDate',
                              },
                              {
                                Header: 'End Date',
                                accessor: 'endDate',
                              },
                              {
                                Header: 'Departure Port',
                                accessor: 'departure',
                              },
                              {
                                Header: 'Arrival Port',
                                accessor: 'arrival',
                              },
                              {
                                Header: 'Ship',
                                accessor: 'ship',
                              },
                            ],
                          },
                        ]}
                        defaultPageSize={50}
                        className="-striped -highlight"
                      />
                    )
                  }}
                </Connect>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Voyages
