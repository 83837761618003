import * as React from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import StripeCheckout from 'react-stripe-checkout'
import { createBooking } from './../../graphql/mutations'

const PayButton = ({ booking }) => {
  const handleCharge = async token => {
    try {
      const allVoyageNumbers = booking.voyages.map(voyage => voyage.number)
      const allGuests = booking.guests.map((guest, index) => {
        return [guest.firstName + ' ' + guest.lastName]
      })
      console.log(allVoyageNumbers)
      const result = await API.post('bookingsLambda', '/charge', {
        // reservation location? text, dropdown, detect location?
        body: {
          token,
          charge: {
            currency: booking.currency,
            amount: booking.total,
            metadata: {
              voyages: allVoyageNumbers,
              guests: allGuests,
              agencyID: booking.agencyID,
              agentID: booking.agentID,
              reservationLocation: 'Miami Office',
              // "res agent": booking.id,
              deposit: booking.deposit,
            },
          },
        },
      })
      console.log({ result })

      // Mutation
      const details = {
        user: '1',
        voyages: booking.voyages,
        status: 'Booked',
        bookedDate: booking.bookedDate,
        depositDate: booking.depositDate,
        // agencyID: booking.agencyID,
        // agentID: booking.agentID,
        total: booking.total,
        currency: booking.currency,
        deposit: booking.deposit,
        guests: booking.guests,
        discount: booking.discount,
        paid: result.paid,
      }
      const newItem = await API.graphql(
        graphqlOperation(createBooking, { input: details })
      )

      console.log(newItem)
    } catch (err) {
      console.error
    }
  }
  if (booking.currency === 'USD') {
    return (
      <StripeCheckout
        token={handleCharge}
        email="lennen@seadream.com"
        name="SeaDream Booking"
        description="Seadream booking for vogages"
        label="Book"
        currency={booking.currency}
        stripeKey={process.env.STRIPE_USD_PUBLIC_KEY}
        allowRememberMe={false}
      />
    )
  } else if (booking.currency === 'EUR') {
    return (
      <StripeCheckout
        token={handleCharge}
        email="lennen@seadream.com"
        name="SeaDream Booking"
        description="Seadream booking for vogages"
        label="Book"
        currency={booking.currency}
        stripeKey={process.env.STRIPE_EUR_PUBLIC_KEY}
        allowRememberMe={false}
      />
    )
  }
}

export default PayButton
