// tslint:disable
// this is an auto generated file. This will be overwritten

export const onCreateUser = `subscription OnCreateUser {
  onCreateUser {
    id
    firstName
    lastName
    telephone
    email
  }
}
`;
export const onUpdateUser = `subscription OnUpdateUser {
  onUpdateUser {
    id
    firstName
    lastName
    telephone
    email
  }
}
`;
export const onDeleteUser = `subscription OnDeleteUser {
  onDeleteUser {
    id
    firstName
    lastName
    telephone
    email
  }
}
`;
export const onCreateAgency = `subscription OnCreateAgency {
  onCreateAgency {
    id
    name
  }
}
`;
export const onUpdateAgency = `subscription OnUpdateAgency {
  onUpdateAgency {
    id
    name
  }
}
`;
export const onDeleteAgency = `subscription OnDeleteAgency {
  onDeleteAgency {
    id
    name
  }
}
`;
export const onCreateBooking = `subscription OnCreateBooking {
  onCreateBooking {
    id
    user
    voyages {
      number
      cabinType
      cabin
      portFee
      price
    }
    status
    bookedDate
    depositDate
    agencyID
    agentID
    total
    currency
    historyCurrency
    deposit
    stripeToken
    notes
    guests {
      firstName
      lastName
      email
      phone
      address1
      address2
      country
      city
      state
      zip
    }
    discount
    paid
  }
}
`;
export const onUpdateBooking = `subscription OnUpdateBooking {
  onUpdateBooking {
    id
    user
    voyages {
      number
      cabinType
      cabin
      portFee
      price
    }
    status
    bookedDate
    depositDate
    agencyID
    agentID
    total
    currency
    historyCurrency
    deposit
    stripeToken
    notes
    guests {
      firstName
      lastName
      email
      phone
      address1
      address2
      country
      city
      state
      zip
    }
    discount
    paid
  }
}
`;
export const onDeleteBooking = `subscription OnDeleteBooking {
  onDeleteBooking {
    id
    user
    voyages {
      number
      cabinType
      cabin
      portFee
      price
    }
    status
    bookedDate
    depositDate
    agencyID
    agentID
    total
    currency
    historyCurrency
    deposit
    stripeToken
    notes
    guests {
      firstName
      lastName
      email
      phone
      address1
      address2
      country
      city
      state
      zip
    }
    discount
    paid
  }
}
`;
export const onCreateVoyage = `subscription OnCreateVoyage {
  onCreateVoyage {
    id
    number
    name
    startDate
    endDate
    departure
    arrival
    stops {
      destination
      date
    }
    ship
    portFee
    cabinTypes {
      name
      description
      squareFeet
      meters
      blueprintImage {
        bucket
        region
        key
      }
      images {
        bucket
        region
        key
      }
      balcony
      price
    }
  }
}
`;
export const onUpdateVoyage = `subscription OnUpdateVoyage {
  onUpdateVoyage {
    id
    number
    name
    startDate
    endDate
    departure
    arrival
    stops {
      destination
      date
    }
    ship
    portFee
    cabinTypes {
      name
      description
      squareFeet
      meters
      blueprintImage {
        bucket
        region
        key
      }
      images {
        bucket
        region
        key
      }
      balcony
      price
    }
  }
}
`;
export const onDeleteVoyage = `subscription OnDeleteVoyage {
  onDeleteVoyage {
    id
    number
    name
    startDate
    endDate
    departure
    arrival
    stops {
      destination
      date
    }
    ship
    portFee
    cabinTypes {
      name
      description
      squareFeet
      meters
      blueprintImage {
        bucket
        region
        key
      }
      images {
        bucket
        region
        key
      }
      balcony
      price
    }
  }
}
`;
export const onCreateShip = `subscription OnCreateShip {
  onCreateShip {
    id
    name
    images {
      bucket
      region
      key
    }
    decks {
      id
      number
      images {
        bucket
        region
        key
      }
    }
    cabinTypes {
      name
      description
      squareFeet
      meters
      blueprintImage {
        bucket
        region
        key
      }
      images {
        bucket
        region
        key
      }
      balcony
      price
    }
    cabins {
      cabinType
      number
      handicapped
      deck {
        id
        number
      }
      hold
      booked
      joint
    }
  }
}
`;
export const onUpdateShip = `subscription OnUpdateShip {
  onUpdateShip {
    id
    name
    images {
      bucket
      region
      key
    }
    decks {
      id
      number
      images {
        bucket
        region
        key
      }
    }
    cabinTypes {
      name
      description
      squareFeet
      meters
      blueprintImage {
        bucket
        region
        key
      }
      images {
        bucket
        region
        key
      }
      balcony
      price
    }
    cabins {
      cabinType
      number
      handicapped
      deck {
        id
        number
      }
      hold
      booked
      joint
    }
  }
}
`;
export const onDeleteShip = `subscription OnDeleteShip {
  onDeleteShip {
    id
    name
    images {
      bucket
      region
      key
    }
    decks {
      id
      number
      images {
        bucket
        region
        key
      }
    }
    cabinTypes {
      name
      description
      squareFeet
      meters
      blueprintImage {
        bucket
        region
        key
      }
      images {
        bucket
        region
        key
      }
      balcony
      price
    }
    cabins {
      cabinType
      number
      handicapped
      deck {
        id
        number
      }
      hold
      booked
      joint
    }
  }
}
`;
export const onCreateDestination = `subscription OnCreateDestination {
  onCreateDestination {
    id
    name
    description
    images {
      bucket
      region
      key
    }
  }
}
`;
export const onUpdateDestination = `subscription OnUpdateDestination {
  onUpdateDestination {
    id
    name
    description
    images {
      bucket
      region
      key
    }
  }
}
`;
export const onDeleteDestination = `subscription OnDeleteDestination {
  onDeleteDestination {
    id
    name
    description
    images {
      bucket
      region
      key
    }
  }
}
`;
