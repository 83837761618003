import * as React from 'react'
import { Link } from 'gatsby'
import { graphqlOperation } from 'aws-amplify'
import { Connect } from 'aws-amplify-react'
import { listDestinations } from './../../graphql/queries'
import { onCreateDestination } from './../../graphql/subscriptions'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'shards-react'
import PageTitle from './../PageTitle'
import SEO from './../SEO'

// Import React Table
import ReactTable from 'react-table'
import 'react-table/react-table.css'

class Destinations extends React.Component {
  onNewDestination = (prevQuery, newData) => {
    // When we get data about a new album, we need to put in into an object
    // with the same shape as the original query results, but with the new data added as well
    let updatedQuery = Object.assign({}, prevQuery)
    updatedQuery.listDestinations.items = prevQuery.listDestinations.items.concat(
      [newData.onCreateDestination]
    )
    return updatedQuery
  }
  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <SEO title="Destinations" />
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Destinations"
            subtitle="List of all Destinations"
            className="text-sm-left mb-3"
          />
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col>
                    <h6 className="m-0">Destinations</h6>
                  </Col>
                  <Col>
                    <Link className="buttonOveride" to="/app/destinations/new">
                      <Button>New Destination</Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <Connect
                  query={graphqlOperation(listDestinations)}
                  subscription={graphqlOperation(onCreateDestination)}
                  onSubscriptionMsg={this.onNewDestination}
                >
                  {({ data: { listDestinations }, loading, error }) => {
                    if (error) return <h3>Error</h3>
                    if (loading || !listDestinations) return <h3>Loading...</h3>
                    return (
                      <ReactTable
                        data={listDestinations.items}
                        columns={[
                          {
                            Header: '',
                            columns: [
                              {
                                Header: 'Name',
                                accessor: 'name',
                              },
                            ],
                          },
                        ]}
                        defaultPageSize={5}
                        className="-striped -highlight"
                      />
                    )
                  }}
                </Connect>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Destinations
