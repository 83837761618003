import React from 'react'
import { graphqlOperation } from 'aws-amplify'
import { Connect } from 'aws-amplify-react'
import { listShips } from './../../graphql/queries'
import { onCreateShip } from './../../graphql/subscriptions'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'shards-react'
import PageTitle from './../PageTitle'
import { Link } from 'gatsby'
import SEO from './../SEO'

// Import React Table
import ReactTable from 'react-table'
import 'react-table/react-table.css'

class Ships extends React.Component {
  onNewShip = (prevQuery, newData) => {
    // When we get data about a new album, we need to put in into an object
    // with the same shape as the original query results, but with the new data added as well
    let updatedQuery = Object.assign({}, prevQuery)
    updatedQuery.listShips.items = prevQuery.listShips.items.concat([
      newData.onCreateShip,
    ])
    return updatedQuery
  }
  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <SEO title="Ships" />
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Ships"
            subtitle="List of all Ships"
            className="text-sm-left mb-3"
          />
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col>
                    <h6 className="m-0">Ships</h6>
                  </Col>
                  <Col>
                    <Link className="buttonOveride" to="/app/ships/new">
                      <Button>New Ship</Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <Connect
                  query={graphqlOperation(listShips)}
                  subscription={graphqlOperation(onCreateShip)}
                  onSubscriptionMsg={this.onNewShip}
                >
                  {({ data: { listShips }, loading, error }) => {
                    if (error) return <h3>Error</h3>
                    if (loading || !listShips) return <h3>Loading...</h3>
                    return (
                      <ReactTable
                        data={listShips.items}
                        columns={[
                          {
                            Header: '',
                            columns: [
                              {
                                Header: 'Name',
                                accessor: 'name',
                              },
                            ],
                          },
                        ]}
                        defaultPageSize={5}
                        className="-striped -highlight"
                      />
                    )
                  }}
                </Connect>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Ships
