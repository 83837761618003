import * as React from 'react'
import { Link } from 'gatsby'
import { graphqlOperation } from 'aws-amplify'
import { Connect } from 'aws-amplify-react'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'shards-react'
import PageTitle from './../PageTitle'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { listUsers } from './../../graphql/queries'
import { onCreateUser } from './../../graphql/subscriptions'
import SEO from './../SEO'

// Import React Table
import ReactTable from 'react-table'
import 'react-table/react-table.css'

class Users extends React.Component {
  onNewUser = (prevQuery, newData) => {
    let updatedQuery = Object.assign({}, prevQuery)
    updatedQuery.listUsers.items = prevQuery.listUsers.items.concat([
      newData.onCreateUser,
    ])
    return updatedQuery
  }
  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <SEO title="Users" />
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Users"
            subtitle="List of all Users"
            className="text-sm-left mb-3"
          />
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col>
                    <h6 className="m-0">Users</h6>
                  </Col>
                  <Col>
                    <Link className="buttonOveride" to="/app/users/new">
                      <Button>New User</Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <Connect
                  query={graphqlOperation(listUsers)}
                  subscription={graphqlOperation(onCreateUser)}
                  onSubscriptionMsg={this.onNewUser}
                >
                  {({ data: { listUsers }, loading, error }) => {
                    if (error) return <h3>Error</h3>
                    if (loading || !listUsers) return <h3>Loading...</h3>
                    return (
                      <ReactTable
                        data={listUsers.items}
                        columns={[
                          {
                            Header: '',
                            columns: [
                              {
                                Header: 'First Name',
                                accessor: 'firstName',
                              },
                              {
                                Header: 'Last Name',
                                accessor: 'lastName',
                              },
                              {
                                Header: 'Telephone',
                                accessor: 'telephone',
                              },
                              {
                                Header: 'Email',
                                accessor: 'email',
                              },
                            ],
                          },
                        ]}
                        defaultPageSize={25}
                        className="-striped -highlight"
                      />
                    )
                  }}
                </Connect>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Users
